<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('units.view.title') }}
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        style="width: 100%; height: 30vh"
        class="flex justify-center"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="record && !loading">
        <div
          class="flex flex-col lg:flex-row border-b border-gray-200 dark:border-dark-5 pb-5 -mx-5"
        >
          <div
            class="flex flex-1 px-5 items-center justify-center lg:justify-start"
          >
            <div class="ml-5 mr-5 flex justify-between flex-wrap flex-1">
              <div>
                <div
                  class="font-medium text-center mt-3 text-xl"
                  :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
                >
                  {{ i18n('units.fields.code') }}
                </div>
                <div
                  class="flex flex-col justify-center items-center lg:items-start mt-2"
                >
                  {{ presenter(record, 'code') }}
                </div>
              </div>
              <div>
                <div
                  class="font-medium text-center mt-3 text-xl"
                  :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
                >
                  {{ i18n('units.fields.project') }}
                </div>
                <div
                  class="flex flex-col justify-center items-center lg:items-start mt-2"
                >
                  {{
                    presenterLocalization(record.unitTemplate.project, 'name')
                  }}
                </div>
              </div>
              <div>
                <div
                  class="font-medium text-center mt-3 text-xl"
                  :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
                >
                  {{ i18n('units.fields.unitTemplate') }}
                </div>
                <div
                  class="flex flex-col justify-center items-center lg:items-start mt-2"
                >
                  {{ presenterLocalization(record.unitTemplate, 'title') }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="nav nav-tabs flex-col sm:flex-row justify-center lg:justify-start"
          role="tablist"
        >
          <a
            id="owners-tab"
            data-toggle="tab"
            data-target="#owners"
            href="javascript:;"
            class="py-4 sm:mr-8 active"
            role="tab"
            aria-controls="owners"
            aria-selected="true"
            @click="tab = 'owners'"
          >
            {{ i18n('units.fields.owners') }}
          </a>
          <a
            id="members-tab"
            data-toggle="tab"
            data-target="#members"
            href="javascript:;"
            class="py-4 sm:mr-8"
            role="tab"
            aria-selected="false"
            @click="tab = 'members'"
          >
            {{ i18n('units.fields.members') }}
          </a>
        </div>
      </div>
    </div>
    <!-- END: Profile Info -->

    <div class="intro-y tab-content mt-5">
      <div
        id="owners"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="owners-tab"
        v-if="tab == 'owners'"
      >
        <usersTab :id="id" type="owned" />
      </div>
      <div
        id="members"
        class="tab-pane active"
        role="tabpanel"
        aria-labelledby="members-tab"
        v-if="tab == 'members'"
      >
        <usersTab :id="id" type="membered" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GenericModel } from '@/shared/model/generic-model'
import usersTab from '@/views/units/users-tab.vue'

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    usersTab
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'unit/view/record',
      loading: 'unit/view/loading'
    })
  },
  data() {
    return {
      tab: 'owners'
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.units')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.units')
    }
  },
  async mounted() {
    await this.doFind(this.id)
  },
  methods: {
    ...mapActions({
      doFind: 'unit/view/doFind'
    }),
    presenter(row, fieldName) {
      return GenericModel.presenter(row, fieldName)
    },
    presenterLocalization(row, fieldName) {
      return GenericModel.presenterLocalization(row, fieldName)
    },
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>
